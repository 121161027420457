import React, { useCallback, useEffect, useRef, useState, createContext, useContext } from 'react'
import localStorage from 'local-storage'
import { constants } from 'helpers'
import { useUser } from 'modules/user'
import { getPostponedItems } from 'modules/queue'


type ProductsInQueueContextValue = {
  queueItems: QueueModule.AddItemsInput
  count: number
  updateCount: () => void
}

const ProductsInQueueContext = createContext<ProductsInQueueContextValue>(null)

export const useProductsInQueueContext = () => useContext(ProductsInQueueContext)


const ProductsInQueueProvider: React.CFC = ({ children }) => {
  const { user } = useUser()

  const productsInQueueQty = user?.analyticsMetadata?.productsInQueueQty || 0
  const countInQueueRef = useRef<number>()

  countInQueueRef.current = productsInQueueQty

  const [ count, setCount ] = useState<number>(productsInQueueQty)
  const [ queueItems, setQueueItems ] = useState<QueueModule.AddItemsInput>([])

  const updateCount = useCallback(() => {
    const postponedItems = getPostponedItems()
    const highPriorityProducts = localStorage.getItem<QueueModule.AddItemsInput>(constants.localStorageNames.queueHighPriorityProducts)
    const lowPriorityProducts = localStorage.getItem<QueueModule.AddItemsInput>(constants.localStorageNames.queueLowPriorityProducts)

    const postponedProductsCount = ([] as QueueModule.AddItemsInput).concat(highPriorityProducts, postponedItems, lowPriorityProducts)
      .filter(Boolean)
      .length

    const finalCount = countInQueueRef.current + postponedProductsCount

    setCount(finalCount)
    setQueueItems(postponedItems)
  }, [])

  useEffect(() => {
    updateCount()
  }, [ productsInQueueQty, updateCount ])

  return (
    <ProductsInQueueContext.Provider value={{ queueItems, count, updateCount }}>
      {children}
    </ProductsInQueueContext.Provider>
  )
}


export default ProductsInQueueProvider
