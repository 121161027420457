import React, { memo } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import AiSummaryBlock from 'compositions/landings/AiSummaryBlock/AiSummaryBlock'

import GetStartedButton from '../GetStartedButton/GetStartedButton'
import messages from './messages'


type AiSummarySectionProps = {
  className?: string
}

const AiSummarySection: React.FunctionComponent<AiSummarySectionProps> = (props) => {
  const { className } = props

  const { isMobile } = useDevice()

  const buttonProps = {
    title: messages.title,
    size: 56 as const,
    width: (isMobile ? null : 280 as const),
    fullWidthOnMobile: true,
    className: isMobile ? 'mt-24' : 'mt-32',
  }

  const button = (
    <GetStartedButton
      {...buttonProps}
      placement="AI reviews summary block"
    />
  )

  return (
    <AiSummaryBlock
      className={cx(className, isMobile && 'pb-36')}
      button={button}
    />
  )
}


export default memo(AiSummarySection)
