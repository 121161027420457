import React, { useMemo } from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'

import { WidthContainer } from 'components/layout'
import { Image, Text, type TextStyle, type ImageProps, RichText } from 'components/dataDisplay'


type BaseQuoteBlockProps = {
  className?: string
  supTitle?: string | Intl.Message
  text: string | Intl.Message
  textStyle?: TextStyle
  authorName?: string | Intl.Message
  authorPosition?: string | Intl.Message
  authorImage?: ImageProps
}

type RichTextQuoteBlockProps = Omit<BaseQuoteBlockProps, 'text' | 'textStyle'> & {
  richText: string
}

type QuoteBlockProps = BaseQuoteBlockProps | RichTextQuoteBlockProps

const QuoteBlock: React.FunctionComponent<QuoteBlockProps> = (props) => {
  const {
    className, supTitle, text, richText, textStyle = 'sh1',
    authorName, authorPosition, authorImage,
  } = props

  const intl = useIntl()

  const isRichText = 'richText' in props

  const quotedText = useMemo(() => {
    return isRichText ? null : `“${intl.formatMessage(text).trim()}”`
  }, [ text, intl, isRichText ])

  return (
    <WidthContainer className={className}>
      <div className="mx-auto max-w-752 border-y border-solid border-gold-50 py-24 text-center">
        {
          Boolean(supTitle) && (
            <Text
              className="mb-24"
              message={supTitle}
              style="eye2"
              color="gold-70"
            />
          )
        }
        {
          isRichText
            ? <RichText className="mx-auto max-w-[688rem]" message={richText} />
            : <Text className="mx-auto max-w-[688rem]" message={quotedText} style={textStyle} />
        }
        {
          Boolean(authorName) && (
            <div className="mt-48">
              <Text
                message={authorName}
                style="sh5"
                color="gold-50"
              />
              {
                Boolean(authorPosition) && (
                  <Text
                    className="mt-4"
                    message={authorPosition}
                    style="p2"
                  />
                )
              }
            </div>
          )
        }
        {
          Boolean(authorImage) && (
            <Image className={cx(authorImage.className, 'mt-48')} src={authorImage} />
          )
        }
      </div>
    </WidthContainer>
  )
}


export default QuoteBlock
