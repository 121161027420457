import React from 'react'
import cx from 'classnames'
import type { Label } from 'typings/graphql'

import { Icon, type IconName } from 'components/dataDisplay'


export const keyToIconNameMap: Record<ProductFragment.GraphicLabel, IconName> = {
  'BESTSELLER': '48/bestseller',
  'CHARITY': '48/charity',
  'CLEAN': '48/clean',
  'LIMITED_EDITION': '48/limited-time',
  'NEW_ARRIVAL': '48/new',
  'SOLD_OUT': '48/out-of-stock',
}

export type GraphicLabelsProps = {
  className?: string
  items: Label[]
  limit?: 1 | 2 // we limit amount of labels for product cards: 2 for Detailed, 1 for Small
}

const ProductGraphicLabels: React.FunctionComponent<GraphicLabelsProps> = (props) => {
  let { className, items, limit } = props

  if (!items?.length) {
    return null
  }

  if (limit && items.length > limit) {
    items = items.slice(0, limit)
  }

  return (
    <div className={cx(className, 'grid grid-cols-1 gap-16')}>
      {
        items.map((key) => (
          Boolean(keyToIconNameMap[key]) && (
            <Icon className="mx-auto" key={key} name={keyToIconNameMap[key]} aria-label={key} />
          )
        ))
      }
    </div>
  )
}


export default React.memo(ProductGraphicLabels)
