export default {
  price: {
    en: 'Price',
  },
  old: {
    en: 'Price reduced from',
  },
  new: {
    en: 'On sale at',
  },
  memberPrice: {
    en: 'Member price:',
  },
  salePrice: {
    en: 'Sale price:',
  },
  regularPrice: {
    en: 'Regular price: <s>{price}</s>',
  },
}
