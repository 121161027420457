import links from 'links'
import { usePathname } from 'router'

import type { HomePageViewProps } from 'pages/landing/View/View'

import messages from './messages'


type CustomButtonsCreatorParams = {
  pathname: string
  price: string
  priceWithDiscount: string
}

const getCustomButtonsByPathname = ({ pathname, price, priceWithDiscount }: CustomButtonsCreatorParams): HomePageViewProps['customBlocksButtons'] | null => {

  if (pathname === links.andreDrummond) {
    return {
      brandsQuality: {
        actionType: 'quiz' as const,
        title: {
          ...messages.andreDrummond.button,
          values: { price: priceWithDiscount || price },
        },
      },
      bottomBanner: {
        actionType: 'quiz' as const,
        title: {
          ...messages.andreDrummond.button,
          values: { price: priceWithDiscount || price },
        },
      },
      stickyBar: {
        actionType: 'subscribe' as const,
        title: {
          ...messages.andreDrummond.shortButton,
          values: { price: priceWithDiscount || price },
        },
      },
    }
  }

  return null
}

const useLandingCustomButtons = (props: Pick<CustomButtonsCreatorParams, 'price' | 'priceWithDiscount'>) => {
  const pathname = usePathname()

  return getCustomButtonsByPathname({ pathname, ...props })
}


export default useLandingCustomButtons
