import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { useVisibilityTrigger } from 'intersection-observer'
import { track } from 'analytics'
import links from 'links'
import { CheckedList, Text, Image } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'

import GetStartedButton from '../GetStartedButton/GetStartedButton'

import mobileImage from './images/mobile.png'
import desktopImage from './images/desktop.png'

import messages from './messages'


type WhatYouGetSectionProps = {
  className?: string
  startButtonMessage?: string | Intl.Message
}

const WhatYouGetSection: React.FunctionComponent<WhatYouGetSectionProps> = (props) => {
  const { className, startButtonMessage } = props
  const { isMobile } = useDevice()

  const ref = useVisibilityTrigger(() => {
    track('Banner display', {
      placement: 'Here’s what you get',
      content: 'Quiz',
      link: links.smartRecommendations,
    })
  })

  const button = (
    <GetStartedButton
      className="mt-24"
      title={startButtonMessage}
      width={320}
      size={56}
      fullWidthOnMobile
      placement="Here’s what you get"
    />
  )

  if (isMobile) {
    return (
      <WidthContainer className={cx(className, 'flex flex-col items-center')} ref={ref}>
        <Text message={messages.title} style="h5" />
        <Image className="mt-16 w-[147rem]" src={mobileImage} remWidth={147} alt="A vial and a case" />
        <CheckedList className="mt-16" items={messages.items} gap={16} />
        {button}
      </WidthContainer>
    )
  }

  return (
    <WidthContainer className={cx(className, 'flex max-h-[427rem] items-center justify-between bg-light-beige px-64')} ref={ref}>
      <Image className="self-end" src={desktopImage} remWidth={350} alt="A vial and a case" />
      <div className="flex flex-col items-start">
        <Text message={messages.title} style="h3" />
        <CheckedList className="mt-24" items={messages.items} gap={16} />
        {button}
      </div>
    </WidthContainer>
  )
}


export default React.memo(WhatYouGetSection)
