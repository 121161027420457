import React from 'react'

import { Text, type TextStyle } from 'components/dataDisplay'

import { usePriceRange, type TradingItemLike } from './utils'


type ProductPriceRangeProps = {
  className?: string
  textStyle?: TextStyle
  tradingItems: TradingItemLike[]
  withCurrency?: boolean
  withNbsp?: boolean
}

const ProductPriceRange: React.FunctionComponent<ProductPriceRangeProps> = (props) => {
  const { className, textStyle = 'h8', tradingItems, withCurrency = true, withNbsp = true } = props

  const { arePricesEqual, maxPriceFormatted, minPriceFormatted } = usePriceRange({
    tradingItems,
    withCurrency,
  })

  if (!maxPriceFormatted && !minPriceFormatted) {
    return null
  }

  const contentNode = arePricesEqual ? (
    <span data-testid="priceRangeEqual">{maxPriceFormatted}</span>
  ) : (
    <>
      <span data-testid="priceRangeMin">{minPriceFormatted}</span>
      {withNbsp ? ' - ' : ' - '}
      <span data-testid="priceRangeMax">{maxPriceFormatted}</span>
    </>
  )

  return (
    <Text className={className} style={textStyle} data-testid="priceRange">
      {contentNode}
    </Text>
  )
}


export default React.memo(ProductPriceRange)
