import useProductCollaboration, { type UseProductCollaborationProps } from './useProductCollaboration'

import messages from './messages'


type UseProductCollaborationLabelProps = UseProductCollaborationProps & {
  isShortLabelText?: boolean
}

const useProductCollaborationLabel = ({ labels, isShortLabelText }: UseProductCollaborationLabelProps ) => {
  const collaboration = useProductCollaboration({ labels })

  if (collaboration === 'saks') {
    if (isShortLabelText) {
      return messages.shortText
    }

    return messages.default
  }
}

export default useProductCollaborationLabel
