import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { type ImageProps, Image, Text } from 'components/dataDisplay'

import QuoteBlock from 'compositions/blocks/QuoteBlock/QuoteBlock'

import messages from './messages'

import glamourImage from './images/glamour.svg?src-loader'
import vogueImage from './images/vogue.svg?src-loader'
import gqImage from './images/gq.svg?src-loader'
import esquireImage from './images/esquire.svg?src-loader'
import buzzFeedImage from './images/buzzfeed.svg?src-loader'
import inStyleImage from './images/instyle.svg?src-loader'


const authorImage = {
  className: 'w-[168rem]',
  ...glamourImage,
  alt: 'Glamour',
  lazy: true,
}

const otherPressImages: ImageProps[] = [
  {
    className: 'w-[100rem]',
    src: vogueImage,
    alt: 'Vogue',
  },
  {
    className: 'w-[60rem]',
    src: gqImage,
    alt: 'GQ',
  },
  {
    className: 'w-[120rem]',
    src: esquireImage,
    alt: 'Esquire',
  },
  {
    className: 'w-[100rem]',
    src: buzzFeedImage,
    alt: 'BuzzFeed',
  },
  {
    className: 'w-[100rem]',
    src: inStyleImage,
    alt: 'inStyle',
  },
]

type PressSectionProps = {
  className?: string
}

const PressSection: React.FunctionComponent<PressSectionProps> = ({ className }) => {
  const { isMobile } = useDevice()

  const otherImages = isMobile ? otherPressImages.slice(0, 3) : otherPressImages

  return (
    <>
      <Text
        className={className}
        message={messages.asFeatured}
        style="h6"
        color="gold-50"
        align="center"
      />
      <QuoteBlock
        className="mt-32"
        text={messages.featuredQuote}
        authorImage={authorImage}
      />
      <div className={cx('mx-auto mt-24 flex max-w-752 justify-between', isMobile ? 'px-36' : 'px-24')}>
        {
          otherImages.map((imageProps) => (
            <Image
              key={imageProps.alt}
              {...imageProps}
              remWidth={120}
            />
          ))
        }
      </div>
    </>
  )
}


export default PressSection
