export default {
  offer: {
    discount: {
      en: '{discount}<br/>off',
    },
    freeTrial: {
      en: 'Free<br/>trial',
    },
    free2ndMonth: {
      en: 'Second<br/>month<br/>free',
    },
    freeProduct: {
      en: 'Free<br/>perfume',
    },
  },
  freeCase: {
    en: '+ a free case',
  },
}
