import { useMemo } from 'react'
import versaceImage from './images/versace.png?src-loader'
import pradaImage from './images/prada.png?src-loader'
import michaelKorsImage from './images/michael-kors.png?src-loader'
import gucciImage from './images/gucci.png?src-loader'
import dolceImage from './images/dolce.png?src-loader'
import burberyImage from './images/burbery.png?src-loader'
import exploreImage from './images/explore.png?src-loader'


const useBrandsImages = () => {
  return useMemo(() => ({
    versace: versaceImage,
    dolce: dolceImage,
    gucci: gucciImage,
    burbery: burberyImage,
    prada: pradaImage,
    michaelKors: michaelKorsImage,
    explore: exploreImage,
  }), [])
}

export default useBrandsImages
