import { useMemo } from 'react'
import { useIntl } from 'intl'

import type { Price, TradingItemLike } from './types'
import { centsFromPrice, getPriceRange } from './getPriceRange'


type UsePriceRangeProps = {
  tradingItems: TradingItemLike[]
  withCurrency?: boolean
}

export const usePriceRange = ({ tradingItems, withCurrency = true }: UsePriceRangeProps) => {
  const intl = useIntl()

  return useMemo(() => {
    const { maxPrice, minPrice } = getPriceRange(tradingItems)

    const arePricesEqual = centsFromPrice(maxPrice) === centsFromPrice(minPrice)

    const maxPriceFormatted = maxPrice && intl.formatPrice(maxPrice, { withCurrency })
    const minPriceFormatted = minPrice && intl.formatPrice(minPrice, { withCurrency })

    return {
      arePricesEqual,
      maxPrice,
      maxPriceFormatted,
      minPrice,
      minPriceFormatted,
    }
  }, [ intl, tradingItems, withCurrency ])
}
