import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Text, HeroMediaBlock } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'

// TODO After the home page is ready and all banners are added, we will need to decide whether we need a default image for desktop and mobile - added on 2021-12-30 by vkudla
import defaultDesktopImage from './images/desktop-bg.jpg'
import defaultMobileImage from './images/mobile-bg.jpg'


type ButtonProps = {
  className: string
  style: string
  fullWidthOnMobile: boolean
}

export type HeroBaseProps = {
  className?: string
  title: Intl.Message | string
  text?: Intl.Message | string
  greeting?: Intl.Message | string
  isFetching?: boolean
  mobileImage?: string
  desktopImage?: string
  desktopContentAtTop?: boolean
  wideMT?: boolean
  wideMB?: boolean
  button: React.ReactElement<ButtonProps>
  'data-testid'?: string
}

const HeroBase: React.CFC<HeroBaseProps> = (props) => {
  const {
    className, title, text, greeting, mobileImage, desktopImage, button,
    isFetching = false, desktopContentAtTop = false, wideMT, wideMB, children,
    'data-testid': dataTestId = 'heroBanner',
  } = props

  const { isMobile } = useDevice()

  if (isMobile) {
    const mobileImageSrc = mobileImage || defaultMobileImage

    return (
      <HeroMediaBlock className={className} data-testid={dataTestId}>
        <HeroMediaBlock.Image src={mobileImageSrc} height={320} />
        {
          !isFetching && (
            <div className="relative bg-black px-24 pb-32 pt-24 text-white">
              {
                Boolean(greeting) && (
                  <Text
                    className="mb-8"
                    message={greeting}
                    style="eye2"
                    color="gold-30"
                  />
                )
              }
              <Text
                message={title}
                style="h4"
                html
              />
              {
                Boolean(text) && (
                  <Text
                    className="mt-8"
                    message={text}
                    style="p1"
                    html
                  />
                )
              }
              {children}
              {
                React.cloneElement(button, {
                  className: 'mt-16',
                  style: 'gold-30',
                  fullWidthOnMobile: true,
                })
              }
            </div>
          )
        }
      </HeroMediaBlock>
    )
  }

  const desktopImageSrc = desktopImage || defaultDesktopImage

  return (
    <HeroMediaBlock className={cx(className, 'relative')} wideMT={wideMT} wideMB={wideMB} data-testid={dataTestId}>
      <HeroMediaBlock.Image src={desktopImageSrc} height={544} />
      {
        !isFetching && (
          <WidthContainer className={cx('absolute inset-0 flex h-full py-56', desktopContentAtTop ? 'items-start' : 'items-center')}>
            <div className="max-w-464">
              {
                Boolean(greeting) && (
                  <Text
                    className="mb-24"
                    message={greeting}
                    style="eye2"
                  />
                )
              }
              <Text
                message={title}
                style="h3"
                html
              />
              {
                Boolean(text) && (
                  <Text
                    className="mt-24"
                    message={text}
                    style="p1"
                    html
                  />
                )
              }
              {children}
              {
                React.cloneElement(button, {
                  className: 'mt-24',
                  style: 'primary',
                })
              }
            </div>
          </WidthContainer>
        )
      }
    </HeroMediaBlock>
  )
}


export default React.memo(HeroBase)
