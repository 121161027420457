import type { Price, TradingItemLike } from './types'


export const centsFromPrice = (price: Price): number => {
  const { amount, amountCents } = price || {}

  return amountCents || amount || 0
}

// TODO: Add membership discount support - added on 2024-10-10 by girilloid
export const getPriceRange = (tradingItems: TradingItemLike[]) => {
  return (tradingItems || []).reduce<{ maxPrice: Price, minPrice: Price }>((accumulator, tradingItem) => {
    const { inStock, prices, section } = tradingItem

    if (!inStock || section === 'Subscription') {
      return accumulator
    }

    const { discountPrice, standardPrice } = prices

    const tradingItemPrice = discountPrice || standardPrice

    if (accumulator.maxPrice === null) {
      accumulator.maxPrice = tradingItemPrice
    }

    if (accumulator.minPrice === null) {
      accumulator.minPrice = tradingItemPrice
    }

    const tradingItemPriceCents = centsFromPrice(tradingItemPrice)
    const maxPriceCents = centsFromPrice(accumulator.maxPrice)
    const minPriceCents = centsFromPrice(accumulator.minPrice)

    if (tradingItemPriceCents > maxPriceCents) {
      accumulator.maxPrice = tradingItemPrice
    }

    if (tradingItemPriceCents < minPriceCents) {
      accumulator.minPrice = tradingItemPrice
    }

    return accumulator
  }, {
    maxPrice: null,
    minPrice: null,
  })
}
