export default {
  title: {
    en: `Here's what you get:`,
  },
  items: [
    {
      en: '1 bottle of your choice every month, each holding roughly <b>120 sprays.</b>',
    },
    {
      en: 'After you subscribe, you can upgrade your plan to get<br class="onlyDesktop"/> <b>1, 2 or 3 bottles of your choice</b> every month.',
    },
    {
      en: '<b>Free</b> atomizer case in your first order.',
    },
    {
      en: '<b>Skip a month or cancel</b> anytime, no worries.',
    },
  ],
}
