import React from 'react'
import { useDevice } from 'device'
import BottomBannerBase from 'compositions/landings/BottomBanner/BottomBanner'

import type { HomePageCustomBlockButton } from 'pages/landing/View/View'

import GetStartedButton from '../GetStartedButton/GetStartedButton'

import messages from './messages'


type BottomBannerProps = {
  className?: string
  customButton?: HomePageCustomBlockButton
  startButtonMessage?: string | Intl.Message
}

const BottomBanner: React.FunctionComponent<BottomBannerProps> = (props) => {
  const { className, startButtonMessage, customButton } = props
  const { isMobile } = useDevice()

  const button = (
    <GetStartedButton
      actionType={customButton?.actionType || (isMobile ? 'quiz' : 'subscribe')}
      title={startButtonMessage || (isMobile ? undefined : messages.buttonTitle)}
      style="gold-30"
      size={56}
      width={320}
      fullWidthOnMobile
      {...customButton}
      placement="Page bottom"
    />
  )

  return (
    <BottomBannerBase
      className={className}
      button={button}
    />
  )
}


export default BottomBanner
