import { useDevice } from 'device'
import links from 'links'
import { useIntl } from 'intl'
import { usePathname } from 'router'
import { cx, devicePx } from 'helpers'
import { buttonMessages } from 'components/inputs'

import type { HeroSectionProps } from 'pages/landing/View/components/HeroSection/HeroSection'

import bouqsLogo from '../images/bouqsLogo.png?src-loader'
import andreDrummondHeroMobileBackground from '../images/andre-drummond-hero-mobile.jpg?src-loader'
import andreDrummondHeroDesktopBackground from '../images/andre-drummond-hero-desktop.jpg?src-loader'
import andreDrummondHeroWithCaseMobileBackground from '../images/andre-drummond-hero-with-case-mobile.jpg?src-loader'
import andreDrummondHeroWithCaseDesktopBackground from '../images/andre-drummond-hero-with-case-desktop.jpg?src-loader'
import douglasEllimanHeroBackgroundDesktop from '../images/douglas-elliman-hero-desktop.jpg?src-loader'
import douglasEllimanHeroBackgroundMobile from '../images/douglas-elliman-hero-mobile.jpg?src-loader'
import douglasEllimanLogoDesktop from '../images/douglas-elliman-logo-desktop.png?src-loader'
import douglasEllimanLogoMobile from '../images/douglas-elliman-logo-mobile.png?src-loader'
import accessDevelopmentLogo from '../images/access-development-logo-mobile.png?src-loader'

import twelveDaysHeroMobile from '../images/twelve-days-hero-mobile.jpg?src-loader'
import twelveDaysHeroDesktop from '../images/twelve-days-hero-desktop.jpg?src-loader'
import twelveDaysBadgeMobile from '../images/twelve-days-badge-mobile.svg?src-loader'
import twelveDaysBadgeDesktop from '../images/twelve-days-badge-desktop.svg?src-loader'

import messages from './messages'


type CustomBlocksCreatorParams = {
  isMobile: boolean
  pathname: string
  price: string
  priceWithDiscount: string
  offerType: SubscriptionModule.OfferType
  intl: Intl.Manager
}

const getCustomBlocksByPathname = (props: CustomBlocksCreatorParams): HeroSectionProps['customBlocks'] | null => {
  const { intl, pathname, isMobile, price, priceWithDiscount, offerType } = props
  if (pathname === links.nift) {
    return {
      title: {
        message: messages.nift.title,
        style: isMobile ? 'h4' : 'h2',
      },
      text: {
        message: messages.nift.text,
        style: isMobile ? 'p2' : 'sh4',
      },
      note: {
        message: messages.nift.note,
        style: isMobile ? 'p2' : 'sh6',
      },
    }
  }

  if (pathname === links.bouqs) {
    return {
      title: {
        message: messages.bouqs.title,
        style: isMobile ? 'h4' : 'h2',
      },
      text: {
        message: messages.bouqs.text,
        style: isMobile ? 'p2' : 'sh4',
      },
      note: {
        message: messages.bouqs.note,
        style: isMobile ? 'p2' : 'sh6',
      },
      timer: {
        withTitle: false,
      },
      logo: {
        src: bouqsLogo,
        alt: 'Bouqs',
      },
    }
  }

  if (pathname === links.andreDrummond) {
    const buttonMessage = priceWithDiscount ? messages.andreDrummond.buttonWithDiscount : messages.andreDrummond.button
    return {
      title: {
        message: messages.andreDrummond.title,
        style: isMobile ? 'h4' : 'h2',
        color: 'gold-30',
      },
      text: {
        className: isMobile ? 'mt-8' : 'mt-24 max-w-464 font-normal',
        message: {
          ...messages.andreDrummond.text,
          values: {
            price,
            priceWithDiscount,
            afterTextStyle: priceWithDiscount ? (!isMobile && 'font-bold') : 'hidden',
          },
        },
        style: isMobile ? 'p2' : 'sh3',
        color: 'white',
      },
      timer: {
        withTitle: true,
        titleColor: 'gold-30',
        captionColor: 'gold-30',
        backgroundColor: 'gold-70',
        countdownColor: 'white',
      },
      button: {
        style: 'gold-30',
        actionType: 'quiz',
        title: {
          ...buttonMessage,
          values: {
            price,
            priceWithDiscount,
          },
        },
      },
      background: offerType
        ? isMobile ? andreDrummondHeroWithCaseMobileBackground : andreDrummondHeroWithCaseDesktopBackground
        : isMobile ? andreDrummondHeroMobileBackground : andreDrummondHeroDesktopBackground,
    }
  }

  if (pathname === links.ellimanperks) {
    return {
      background: isMobile ? douglasEllimanHeroBackgroundMobile : douglasEllimanHeroBackgroundDesktop,
      logo: {
        src: isMobile ? douglasEllimanLogoMobile : douglasEllimanLogoDesktop,
        alt: 'Douglas Elliman logo',
        height: isMobile ? devicePx(39) : 56,
        width: isMobile ? devicePx(287) : 408,
        className: isMobile ? 'max-h-[39rem]' : 'max-h-[56rem]',
      },
      title: {
        color: 'black',
        className: 'max-w-464',
        message: {
          ...messages.douglasElliman.title,
          values: { discount: 60 },
        },
      },
      text: {
        className: cx('pr-0', isMobile ? 'mx-auto max-w-336 pt-12' : 'max-w-[420rem] pt-20'),
        color: 'black',
        message: {
          ...messages.douglasElliman.text,
          values: {
            priceWithDiscount: intl.formatPrice(700, { removeZeroCents: true }),
            price,
          },
        },
      },
      note: isMobile ? undefined : {
        message: messages.douglasElliman.note,
        color: 'white',
      },
      timer: {
        forceShow: true,
        titleColor: 'white',
        captionColor: 'black',
        backgroundColor: 'light-beige',
        countdownColor: 'black',
        withTitle: isMobile,
        title: isMobile ? messages.douglasElliman.note : undefined,
        titleStyle: isMobile ? 'p2' : undefined,
        className: isMobile ? 'mt-20 flex items-center gap-x-20 justify-between' : 'mt-40',
      },
      button: { actionType: 'quiz', title: messages.douglasElliman.quiz },
    }
  }

  if (pathname === links.accessPerks) {
    return {
      background: isMobile ? douglasEllimanHeroBackgroundMobile : douglasEllimanHeroBackgroundDesktop,
      logo: {
        src: accessDevelopmentLogo,
        alt: 'Access development logo',
        height: isMobile ? devicePx(30) : 38,
        width: isMobile ? devicePx(217) : 275,
        className: isMobile ? 'max-h-[30rem]' : 'max-h-[38rem]',
      },
      title: {
        color: 'black',
        className: 'max-w-464',
        message: {
          ...messages.accessDevelopment.title,
          values: { discount: 60 },
        },
      },
      text: {
        className: cx('pr-0', isMobile ? 'mx-auto max-w-336 pt-12' : 'max-w-[420rem] pt-20'),
        color: 'black',
        message: {
          ...messages.accessDevelopment.text,
          values: {
            priceWithDiscount: intl.formatPrice(700, { removeZeroCents: true }),
            price,
          },
        },
      },
      note: isMobile ? undefined : {
        message: messages.accessDevelopment.note,
        color: 'white',
      },
      timer: {
        forceShow: true,
        titleColor: 'white',
        captionColor: 'black',
        backgroundColor: 'light-beige',
        countdownColor: 'black',
        withTitle: isMobile,
        title: isMobile ? messages.accessDevelopment.note : undefined,
        titleStyle: isMobile ? 'p2' : undefined,
        className: isMobile ? 'mt-20 flex items-center gap-x-20 justify-between' : 'mt-40',
      },
      button: { actionType: 'quiz', title: messages.accessDevelopment.quiz },
    }
  }

  if (pathname === links.twelveDays) {
    return {
      background: isMobile ? twelveDaysHeroMobile : twelveDaysHeroDesktop,
      title: {
        message: messages.twelveDays.title,
        color: 'gold-30',
      },
      text: {
        message: messages.twelveDays.text,
        className: cx('pr-0', isMobile ? 'pt-12' : 'pt-20'),
        color: 'light-beige',
      },
      timer: {
        withTitle: true,
        titleColor: 'light-beige',
        captionColor: 'light-beige',
        backgroundColor: 'light-beige',
        countdownColor: 'black',
      },
      button: {
        actionType: 'subscribe',
        title: {
          ...buttonMessages.subscribeForPriceWithDiscount,
          values: {
            priceWithDiscount,
            price,
          },
        },
      },
      badge: {
        className: isMobile ? 'bottom-24 left-16 size-[142rem]' : 'right-[174rem] top-48 size-[199rem]',
        offerColor: isMobile ? 'gold-30' : 'dark-red',
        freeCaseColor: isMobile ? 'white' : 'black',
        style: {
          background: `url(${isMobile ? twelveDaysBadgeMobile.src : twelveDaysBadgeDesktop.src}) no-repeat center`,
        },
      },
    }
  }

  return null
}

const useLandingCustomHeroBlocks = (props: Pick<CustomBlocksCreatorParams, 'offerType' | 'price' | 'priceWithDiscount'>) => {
  const pathname = usePathname()
  const { isMobile } = useDevice()
  const intl = useIntl()

  return getCustomBlocksByPathname({ intl, pathname, isMobile, ...props })
}


export default useLandingCustomHeroBlocks
