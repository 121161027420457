import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { twcx } from 'helpers'
import { Text, type TextColor } from 'components/dataDisplay'
import DiscountBadgeBase, { type DiscountBadgeSize } from 'compositions/DiscountBadgeBase/DiscountBadgeBase'

import messages from './messages'


export type SubscriptionOfferBadgeProps = {
  className?: string
  offerType: SubscriptionModule.OfferType
  discount?: string
  offerColor?: TextColor
  freeCaseColor?: TextColor
  style?: React.CSSProperties
}

const SubscriptionOfferBadge: React.FC<SubscriptionOfferBadgeProps> = (props) => {
  const { className, offerType, discount, offerColor, freeCaseColor, style } = props
  const { isMobile } = useDevice()

  const defaultOfferTextSize = isMobile ? 'text-[33rem]/[31rem]' : 'text-[48rem]/[46rem]'
  const smallOfferTextSize = isMobile ? 'text-[19rem]/[20rem]' : 'text-[31rem]/[32rem]'
  const offerTextSize = offerType === 'discount' || offerType === 'freeTrial' ? defaultOfferTextSize : smallOfferTextSize

  return (
    <DiscountBadgeBase
      className={twcx('font-tt-norms-pro absolute font-bold uppercase', className)}
      containerClassName={style?.background ? 'border-none' : null}
      size={isMobile ? 144 : 199}
      style={style}
    >
      <div>
        <Text
          className={offerTextSize}
          message={{ ...messages.offer[offerType], values: { discount } }}
          style={null}
          color={offerColor || 'gold-30'}
          withInnerLinksStyling={false}
          html
        />
        <Text
          className={cx('mt-4', isMobile ? 'text-[12rem]/[12rem]' : 'text-[16rem]/[16rem]')}
          message={messages.freeCase}
          style={null}
          color={freeCaseColor || 'white'}
          withInnerLinksStyling={false}
          html
        />
      </div>
    </DiscountBadgeBase>
  )
}

export default SubscriptionOfferBadge
