import React from 'react'
import { twcx } from 'helpers'


export type DiscountBadgeSize = 100 | 120 | 134 | 140 | 144 | 155 | 199 | null

type DiscountBadgeProps = {
  className?: string
  containerClassName?: string
  size?: DiscountBadgeSize
  children: React.ReactNode
  style?: React.CSSProperties
  'data-testid'?: string
}

const DiscountBadgeBase: React.FC<DiscountBadgeProps> = (props) => {
  const { children, className, containerClassName, size = 134, style, 'data-testid': dataTestId } = props

  const rootClassName = twcx('absolute rounded-full bg-black', {
    'size-[100rem] p-4': size === 100,
    'size-[120rem] p-4': size === 120,
    'size-[134rem] p-6': size === 134,
    'size-[140rem] p-6': size === 140,
    'size-[144rem] p-6': size === 144,
    'size-[155rem] p-6': size === 155,
    'size-[199rem] p-8': size === 199,
  }, className)

  const finalContainerClassName = twcx(
    'border-pink flex h-full flex-col items-center justify-center rounded-full border text-center',
    containerClassName
  )

  return (
    <div className={rootClassName} style={style} data-testid={dataTestId}>
      <div className={finalContainerClassName}>
        {children}
      </div>
    </div>
  )
}

export default DiscountBadgeBase
