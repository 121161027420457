import links from 'links'


const getForcedCouponByPathname = (pathname: string) => {
  // /men/free-trial -> /free-trial
  const genderLessPathName = pathname.replace('/men', '')

  if (genderLessPathName === links.freeTrial || genderLessPathName === links.freeTrialCa) {
    return 'FREE_TRIAL'
  }

  if (pathname === links.freePerfume) {
    return 'SUBFREEPRODUCT'
  }

  if (pathname === links.secondMonthFree) {
    return '2ND_MTH_FREE_8FE998'
  }

  if (pathname === links.nift) {
    return 'HD60'
  }

  if (pathname === links.bouqs) {
    return 'HD60'
  }

  if (pathname === links.ellimanperks) {
    return 'HD60'
  }

  if (pathname === links.andreDrummond) {
    return 'HD50'
  }

  if (pathname === links.accessPerks) {
    return 'HD60'
  }
}


export default getForcedCouponByPathname
