import { usePathname, useSearchParams } from 'router'
import links from 'links'
import { useLocalePreferences } from 'modules/localePreferences'


const useLandingCountry = (): Intl.CountryCode => {
  const pathname = usePathname()
  const [ { utm_campaign: utmCampaign } ] = useSearchParams()
  const { localePreferences } = useLocalePreferences()

  const isCanada = (
    pathname === links.freeTrialCa
    || pathname === links.freeTrialMenCa
    || Boolean(utmCampaign?.match(/canada/i))
  )

  // TODO in this case country can change, so we need to change current locale, or refresh the page - added on 2024-05-19 by maddoger
  return isCanada ? 'CA' : localePreferences.country
}


export default useLandingCountry
