import React, { memo } from 'react'
import cx from 'classnames'

import { Icon, Text } from 'components/dataDisplay'

import messages from './messages'


type AiSummaryNoteBlockProps = {
  className?: string
}

const AiSummaryNoteBlock: React.FunctionComponent<AiSummaryNoteBlockProps> = (props) => {
  const { className } = props

  return (
    <div className={cx(className, 'flex items-center' )}>
      <Icon className="size-[44rem] min-h-[44rem] min-w-[44rem]" name="32/ai-generated" />
      <Text className="ml-20 text-left" message={messages.note} style="p5" html />
    </div>
  )
}

export default memo(AiSummaryNoteBlock)
