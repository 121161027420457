import React from 'react'

import BestsellersBlock from 'compositions/landings/BestsellersBlock/BestsellersBlock'
import type { ProductListProps } from 'compositions/landings/BestsellersBlock/components/ProductList/ProductList'

import messages from './messages'


type BestsellersSectionProps = {
  className?: string
  title?: Intl.Message
  buttonProps: ProductListProps['buttonProps']
  fetchMoreButtonTitle?: ProductListProps['fetchMoreButtonTitle']
  fetchMoreButtonStyle?: ProductListProps['fetchMoreButtonStyle']
}

const BestsellersSection: React.FunctionComponent<BestsellersSectionProps> = (props) => {
  const { className, title, buttonProps, fetchMoreButtonTitle, fetchMoreButtonStyle } = props

  return (
    <BestsellersBlock
      id="products"
      className={className}
      title={title || messages.title}
      withRetailPrice
      buttonProps={buttonProps}
      fetchMoreButtonTitle={fetchMoreButtonTitle}
      fetchMoreButtonStyle={fetchMoreButtonStyle}
    />
  )
}


export default React.memo(BestsellersSection)
