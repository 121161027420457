export default {
  offer: {
    discount: {
      en: '{discount} <br />off',
    },
    freeTrial: {
      en: 'Free<br/>trial',
    },
    free2ndMonth: {
      en: 'Second<br/>month<br/>free',
    },
    freeProduct: {
      en: '1 free <br />scent',
    },
  },
  free: {
    en: 'free',
  },
  freeCase: {
    en: '+ a free case',
  },
  plusFreeCase: {
    en: '+ <br />free <br />case',
  },
  try: {
    en: 'Try your first <br class="onlyMobile" />month for',
  },
  getSecondMonth: {
    en: 'Get your second <br class="onlyMobile" />month <br class="onlyDesktop" />for',
  },
  price: {
    en: '<span class="line-through">{price}</span> <strong>{priceWithDiscount}</strong>',
  },
  timer: {
    en: 'Limited time offer',
  },
}
