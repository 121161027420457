import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import BrandsQualityBlock, { type BrandsQualityBlockProps } from 'compositions/landings/BrandsQualityBlock/BrandsQualityBlock'

import type { HomePageCustomBlockButton } from 'pages/landing/View/View'

import GetStartedButton from '../GetStartedButton/GetStartedButton'


type BrandsSectionProps = {
  className?: string
  customButton?: HomePageCustomBlockButton
  startButtonMessage?: string | Intl.Message
  desktopImage?: BrandsQualityBlockProps['desktopImage']
  mobileImage?: BrandsQualityBlockProps['mobileImage']
}

const BrandsSection: React.FunctionComponent<BrandsSectionProps> = (props) => {
  const { className, startButtonMessage, customButton, desktopImage, mobileImage } = props
  const { isMobile } = useDevice()

  const button = (
    <GetStartedButton
      actionType={customButton?.actionType}
      title={startButtonMessage}
      size={56}
      width={isMobile ? null : 280}
      fullWidthOnMobile
      className={isMobile ? 'mt-24' : 'mt-32'}
      {...customButton}
      placement="Brand block"
    />
  )

  return (
    <BrandsQualityBlock
      className={cx(className, isMobile && 'pb-64')}
      button={button}
      desktopImage={desktopImage}
      mobileImage={mobileImage}
    />
  )
}


export default React.memo(BrandsSection)
